import React, { useEffect, useState } from 'react';
import HeaderBar from '../../components/header-bar';
import { callAppFunc } from '../../utils/jsbridge/index.js';
import { useAPI } from '../../utils/api';
import { navigate } from 'gatsby';
import OrderItem from '../../components/order-item/index.jsx';
import { showLoading, dismissLoading } from '../../utils/loading';
import { convertDate2StringDate } from '../../utils/_dateHandler';
import { pageCheck } from '../../utils/pageCheck';

const OrderType = {
  FINISHED: 30, //完成
  CANCEL: 40, // 取消
  DISPATCH: 20, //配送中
  TEN: 10, // 狀態10
};

const OrderList = () => {
  const api = useAPI();

  const [dispatchOrder, setDispatchOrder] = useState([]);
  const [finishedOrder, setFinishedOrder] = useState([]);

  useEffect(() => {
    showLoading();
    const start = new Date();
    const end = new Date();
    end.setMonth(end.getMonth() - 3);

    const startDate = convertDate2StringDate(start, '-');
    const endDate = convertDate2StringDate(end, '-');
    api
      .getOrders(startDate, endDate)
      .then((res) => {
        dismissLoading();
        if (res.length > 0) {
          const dispatchArray = [];
          const finishedArray = [];
          for (const item of res) {
            console.log('item.order_status_id', item.order_status_id);
            if (item.order_status_id === OrderType.DISPATCH) {
              dispatchArray.push(item);
            } else if (
              item.order_status_id === OrderType.CANCEL ||
              item.order_status_id === OrderType.FINISHED ||
              item.order_status_id === OrderType.TEN
            ) {
              finishedArray.push(item);
            }
            console.log(item);
          }

          setDispatchOrder(dispatchArray);
          setFinishedOrder(finishedArray);
        }
      })
      .catch((error) => {
        dismissLoading();
        console.log(error);
      });
  }, []);

  const goCart = (transNo) => {
    showLoading();
    api
      .reOrder(transNo)
      .then((res) => {
        dismissLoading();
        if (!res) {
          return;
        }

        navigate('/cart', {
          state: { page: '' },
          replace: true,
        });
      })
      .catch((error) => {
        dismissLoading();
        console.log('reorder exception =', error);
      });
  };

  return (
    <>
      <div className="header-bar">
        <HeaderBar
          title="訂單查詢"
          goBack={() => {
            callAppFunc('backHome', {});
          }}
        />
      </div>
      <div className="page">
        <h2>最新訂單</h2>
        {dispatchOrder &&
          dispatchOrder.map((order, index) => (
            <OrderItem
              type={1}
              key={index}
              data={order}
              goNext={(transNo) => {
                pageCheck(api, () => {
                  navigate(`/order/detail/${transNo}`, {
                    replace: false,
                    state: { query_source: 'list' },
                  });
                });
              }}
            />
          ))}
        <h2>過往訂單</h2>
        {finishedOrder &&
          finishedOrder.map((order, index) => (
            <OrderItem
              key={index}
              type={2}
              data={order}
              goNext={(transNo) => {
                pageCheck(api, () => {
                  navigate(`/order/detail/${transNo}`, {
                    replace: false,
                  });
                });
              }}
              reOrder={(transNo) => {
                goCart(transNo);
              }}
            />
          ))}
      </div>
      <style jsx>{`
        .header-bar {
          width: 100%;
          z-index: 1;
          position: fixed;
          top: 0;
        }
        .page {
          padding: 55px 14px 16px 16px;
          height: 100%;
        }
        h2 {
          padding-top: 16px;
        }
      `}</style>
    </>
  );
};

export default OrderList;
